import jwt from 'jsonwebtoken';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import max from 'lodash/max';
import DOMPurify from 'dompurify';
import { state } from '../context';

import {
  StandarisationYes,
  TYPE_LOCATION,
  PIVOT_DOWNLOAD_NAME_LENGTH,
  CALENDAR_YEAR,
  SEO_META_INFO,
} from '../constants';

export const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, 'g'), replace);
};

export const voidFunction = () => {
  //this function is used to set as default value of any function variable
};

export const calcDrawerWidth = (max = false, offset = 100) => {
  const maxWidth = Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
  return maxWidth <= 576 ? maxWidth : max ? maxWidth - offset : 577;
};

export const appendToPageTitle = (title) => {
  document.title = `${title} - ${state.domain ? state.domain?.toUpperCase() : 'NDAP'}`;
};

export const getLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      localStorage.setItem(
        'coords',
        JSON.stringify({ lat: position.coords.latitude, long: position.coords.longitude })
      );
    });
  } else {
    localStorage.removeItem('coords');
  }
};

export const isMobileDevice = () => {
  let isMobile = false; //initiate as false
  // device detection
  if (
    //eslint-disable-next-line
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    //eslint-disable-next-line
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4)
    )
  ) {
    isMobile = true;
  }
  return isMobile;
};

export const isExpired = (token) => {
  const decode = jwt.decode(token);
  const timeNow = new Date();
  const tokenTime = new Date(decode.exp * 1000);
  if (tokenTime < timeNow) return true;
  return false;
};

const replaceAllWildCharater = (searchTerm) => {
  let replaceCharaters = `\\,[,],^,$,.,|,?,*,+,(,)`.split(',');

  replaceCharaters.forEach((item) => (searchTerm = searchTerm.split(`${item}`).join(`\\${item}`)));

  return searchTerm;
};

const matchedWords = ({ sentence, searchTerm = '' }) => {
  return (sentence?.match?.(new RegExp(replaceAllWildCharater(searchTerm), 'gi')) || []).toString().replace(/,/g, '')
    .length;
};

export const textExists = (searchTerm, dataString) => matchedWords({ sentence: dataString, searchTerm });

export const textSearch = ({
  data,
  searchTerm,
  searchKey,
  emptySearchValue = '',
  wordSearch = false,
  order = false,
  otherDims = true,
}) => {
  if (!data) return emptySearchValue;

  if (order) {
    data = orderBy(data, searchKey, Array.isArray(searchKey) ? searchKey.map(() => order) : order);
  }

  if (!searchTerm) return data;

  if (wordSearch) {
    if (typeof searchTerm === 'string' && otherDims) {
      searchTerm = searchTerm.toLowerCase().replace(/\s\s+/g, ' ').split(' ');
    }

    if (typeof searchTerm === 'string' && !otherDims) {
      searchTerm = searchTerm?.toLowerCase()?.replace(/\s\s+/g, ' ');
      const arr = data?.filter((item) =>
        isNaN(item[searchKey])
          ? item[searchKey]?.toLowerCase()?.includes(searchTerm.toLowerCase())
          : (item[searchKey] + '').indexOf(searchTerm) > -1
      );
      return arr || emptySearchValue;
    }

    const searchWord = (item, key) => {
      const text = get(item, key, '').toLowerCase?.().replace(/\s\s+/g, ' ').split(' ');
      return (
        (searchTerm?.filter?.((term) => {
          return text?.filter((dataString) => textExists(term, dataString)).length > 0;
        }).length || 0) > 0
      );
    };

    return (
      data?.filter((item) => {
        if (Array.isArray(searchKey)) {
          return (
            searchKey.filter((key) => {
              return searchWord(item, key);
            }).length > 0
          );
        } else {
          return searchWord(item, searchKey);
        }
      }) || emptySearchValue
    );
  }

  if (typeof data === 'string') {
    /**
     * String to string search
     */
    return textExists(searchTerm, data) ? data : emptySearchValue;
  }

  /**
   * Search in list of array with object
   */
  return data?.filter((item) => textExists(searchTerm, get(item, searchKey))) || emptySearchValue;
};

export const buildUrlParamsFromObj = (obj) => {
  const params = new URLSearchParams(obj);
  return params.toString();
};

export const getUrlParams = () => {
  const params = new URLSearchParams(window.location.search);
  return params.getAll();
};

export const makeKeyToColumnName = (rawString) => {
  let name = '';
  let splitString = rawString.split('');
  splitString.forEach((d, k) => {
    let previous = splitString[k - 1] || '';
    let next = splitString[k + 1] || '';
    if (k === 0) d = d.toUpperCase();
    if (k > 0 && (previous !== previous.toUpperCase() || next === next.toLowerCase()) && d === d.toUpperCase()) {
      name += ' ';
    }
    name += d;
  });

  return replaceAll(name, '_', '');
};

export const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const onlyTextToLowerCase = (text) => replaceAll((text || '').toLowerCase(), /\s|[0-9_]|\W|[#$%^&*()]/gi, '');

export const onlyTextAndNumbers = (text) => replaceAll(text || '', /[^a-z\d\s ]+/gi, '');

export const toShortCurrency = (num) => {
  let isNegative = num < 0;
  var parsedNumber = Math.floor(Math.round(num));

  if (isNegative) parsedNumber *= -1;

  var toBeReturned;

  if (parsedNumber >= 10000000) {
    toBeReturned = (parsedNumber / 10000000).toFixed(1).replace(/\.0$/, '') + 'Cr';
  } else if (parsedNumber >= 100000) {
    toBeReturned = (parsedNumber / 100000).toFixed(1).replace(/\.0$/, '') + 'L';
  } else if (parsedNumber >= 1000) {
    toBeReturned = (parsedNumber / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  } else {
    toBeReturned = parsedNumber;
  }

  if (isNegative) toBeReturned = `-${toBeReturned}`;
  return toBeReturned;
};

export const shadeColor = (color, percent) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  let RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
  let GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
  let BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

export const createColorGroup = (range, color, percentage = 20) => {
  let colors = range.map((r, i) => (i > 0 ? r && shadeColor(color, (i * percentage) / 2) : color));
  return colors.reverse();
};

export const createColorGroupFromPallet = (range, color, percentage = 20) => {
  let colors = range.map((r, i) => (i > 0 ? r && shadeColor(color[i], (i * percentage) / 2) : color[i]));
  return colors.reverse();
};

export const createRange = (data, numberOfGroup = 5) => {
  let groupData = [];
  if (!data?.length) return groupData;

  let minValue = 0;
  let maxValue = Math.ceil(Number(max(data))) + 1;
  let divider = (maxValue - minValue) / numberOfGroup;

  for (let i = minValue; i <= maxValue; i = i + divider) {
    groupData.push(i);
  }

  if (groupData[groupData.length - 1] < maxValue) {
    groupData.push(maxValue);
  }

  return groupData;
};

export const legendTitle = (name = '') => {
  if (!name) {
    return;
  }

  let splits = name.split(' ');
  let finalstring = '';
  let temp = '';

  splits.forEach((string) => {
    if (string.trim().length === 0) return;
    if (temp.length + string.length > 25 || string.startsWith('[')) {
      finalstring = finalstring.concat(temp);
      finalstring = finalstring.concat('<br/ >');
      temp = '';
    }

    if (temp.length > 0) temp = temp.concat(' ');
    temp = temp.concat(string);
  });

  return finalstring.concat(temp);
};

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const keysToCamelCase = (data) => {
  const keys = Object.keys(data);
  const dataObject = {};
  keys.forEach((key) => {
    let variableName = key
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');

    if (variableName === 'key') {
      variableName = 'Key';
    }

    dataObject[variableName] = data[key];
  });

  return dataObject;
};

export const hideSuggestDataset = () => {
  const element = document.getElementById('suggest-dataset');

  if (element) {
    element.style.display = 'none';

    return () => {
      element.style.display = 'block';
    };
  }
};

export const cleanName = (text, replace = '', allSmall = true, pattern = /\W+/g) => {
  let cleanString = (text && String(text).replace(pattern, replace)) || '';

  if (allSmall) return cleanString?.toLowerCase();

  return cleanString;
};

export const getQuery = (query, name, asArray = true) => {
  const value = (query.getAll(name) || '').toString();

  if (asArray) {
    return value.split(',');
  }

  return value;
};

export const roundDecimalPlaces = ({ number, to = 2, defaultValue = 'N/A' }) => {
  return (number && parseFloat(number).toFixed(2)) || defaultValue;
};

export const getUrl = (url = '', params = {}) => {
  if (!url) {
    return window.location.href;
  }

  let urlParams = [];
  let entries = Object.entries(params);

  for (let [key, value] of entries) {
    urlParams.push(`${key}=${value}`);
  }

  return `${window.location.protocol}//${window.location.host}${url && url}${
    urlParams.length ? `?${urlParams.join('&')}` : ''
  }`;
};

export const uniqID = () =>
  `${Math.random().toString(16).slice(10)}-${Math.random().toString(16).slice(10)}-${Math.random()
    .toString(16)
    .slice(10)}-${Math.random().toString(16).slice(10)}`;

export const buildLocationPath = (dimensions = [], region = 'state') => {
  region = region.replace('Name', '').toLowerCase();
  const allLocationDimensions = dimensions.filter(
    (item) => new RegExp(item.DimensionType, 'gi').test(TYPE_LOCATION) && !new RegExp(item.ID, 'gi').test('code')
  );

  const uptoDimension = allLocationDimensions.findIndex((item) => item.ID.toLowerCase() === `${region}name`);
  return allLocationDimensions.slice(0, uptoDimension + 1);
};

export const getLocationAndName = (dimensions = [], region = 'state') => {
  region = region.replace('Name', '').toLowerCase();
  return dimensions.filter(
    (item) =>
      new RegExp(item.DimensionType, 'gi').test(TYPE_LOCATION) &&
      (item.ID.toLowerCase() === `${region}name` || item.ID.toLowerCase() === `${region}code`)
  );
};

export const exportToCsv = (filename, rows) => {
  var parseFileName = filename?.slice(0, PIVOT_DOWNLOAD_NAME_LENGTH)?.replace('.', '');
  var processRow = function (row) {
    var finalVal = '';
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? '' : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  };

  var csvFile = '';
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, parseFileName);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', parseFileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const createVisualizationTitle = (indicaters, dimensions, sources = []) => {
  let names = indicaters.map((item) => `${item.AggregationFunction} of ${item.Description}`);
  let by = dimensions.map((item) => item.ID);

  return { title: `${names.join(' V/S ')} By ${by.join(' AND ')}`, source: ` <b>(${sources.join()})</b>` };
};

export const getLastItem = (text) => (Array.isArray(text) ? text.pop() : String(text).split(',').pop?.());

export const wrapString = (str, width = 60, spaceReplacer = '<br>') => {
  if (str.length > width) {
    var p = width;
    for (; p > 0 && str[p] !== ' '; p--) {}
    if (p > 0) {
      var left = str.substring(0, p);
      var right = str.substring(p + 1);
      return left + spaceReplacer + wrapString(right, width, spaceReplacer);
    }
  }
  return str;
};

export const ColorToHex = (color) => {
  var hexadecimal = color.toString(16);
  return hexadecimal.length === 1 ? '0' + hexadecimal : hexadecimal;
};

export const ConvertRGBtoHex = (color) => {
  if (color.includes('rgb')) {
    let [red, green, blue] = color.replace(/rgb|\(|\)|\s/g, '').split(',');
    let hexa = '#' + ColorToHex(Number(red)) + ColorToHex(Number(green)) + ColorToHex(Number(blue));
    return hexa;
  }

  return color;
};

export const maxYear = (years, onlyID = true) => {
  const maxYearInList = max(years.map(({ ID }) => ID));

  if (onlyID) {
    return maxYearInList;
  }

  return years.filter((item) => item.ID === maxYearInList);
};

export const getObjectEntries = (options) => {
  let params = {};
  for (const [key, value] of options) {
    if (key) {
      params = {
        ...params,
        ...{ [key]: value },
      };
    }
  }
  return params;
};

export const getSourceID = (sourceID, all = false) => {
  if (!sourceID) return all ? [] : 0;

  const sources = Array.isArray(sourceID) ? sourceID : String(sourceID).split(',');

  if (all) {
    return sources;
  }

  return (sources?.length && sources[0]) || 0;
};

export const checkStandarisation = (details) =>
  details?.Standardization === StandarisationYes || details === StandarisationYes;

export const parseStringToNumber = (text) => (isNaN(text) ? text : +text);

export const characterLimit = (string = '', limit = 0) => string.substring(0, limit);

export const addWhen = ({ data, add = false, arrayParse = true }) => {
  if (!add) {
    return arrayParse ? [] : {};
  }

  return arrayParse && !Array.isArray(data) ? [data] : data;
};
export const getLocaleStringByCountry = (num, country) => {
  if (Number.isInteger(Number(num))) return Number(num)?.toLocaleString(country);
  else return num;
};

export const checkScalingFactor = (text = '') => text && text.toLocaleLowerCase() !== 'none';

export const getDynamicPath = (domain) => domain;

export const purifyQueryStringObject = (obj) => {
  if (obj && typeof obj === 'object') {
    const purifiedObj = {};
    Object.entries(obj)?.forEach(([key, value]) => {
      purifiedObj[key] = value === 0 ? DOMPurify.sanitize('0') : DOMPurify.sanitize(value);
    });
    return purifiedObj;
  } else return obj === 0 ? DOMPurify.sanitize('0') : DOMPurify.sanitize(obj);
};

export const parseDataTableYear = (str) => {
  if (str) {
    if (str.includes(CALENDAR_YEAR)) return str;
    const lastTwoDigits = Number(str?.slice(-2));
    const paddedDigits = addPadToNumber(lastTwoDigits);
    return `${str}-${paddedDigits}`;
  }
  return '';
};

export const addPadToNumber = (d) => {
  if (d === 99) {
    return '00';
  }
  return d < 9 ? '0' + (d + 1) : '' + (d + 1);
};

export const updateTitleAndMetaInfo = (title, metaDescription, keywords) => {
  //get default meta info
  const MetaInfo = SEO_META_INFO();

  // Set the title of the page.
  document.title = `${title} - ${state.domain ? state.domain?.toUpperCase() : 'NDAP'}`;

  // Set the meta description of the page.
  const metaDescriptionElement = document.querySelector('meta[name="description"]');
  if (metaDescriptionElement) {
    metaDescriptionElement.content = metaDescription ? metaDescription : MetaInfo.home.description;
  } else {
    const metaDescriptionElement = document.createElement('meta');
    metaDescriptionElement.name = 'description';
    metaDescriptionElement.content = metaDescription ? metaDescription : MetaInfo.home.description;
    document.head.appendChild(metaDescriptionElement);
  }

  // Set the meta kewords of the page.
  const metaKeywordsElement = document.querySelector('meta[name="keywords"]');
  if (metaKeywordsElement) {
    metaKeywordsElement.content = keywords ? keywords : MetaInfo.home.keywords;
  } else {
    const metaKeywordsElement = document.createElement('meta');
    metaKeywordsElement.name = 'keywords';
    metaKeywordsElement.content = keywords ? keywords : MetaInfo.home.keywords;
    document.head.appendChild(metaKeywordsElement);
  }
};

export const isObject = (value) => {
  return typeof value === 'object' && value !== null;
};
